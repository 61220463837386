import React from 'react';
import { GithubLink, LinkedInLink, TwitterLink } from './Links';
import { footerItem } from './Footer.module.scss';

const Footer: React.FC = () => {
  return (
    <footer>
      <div className="container">
        <div className={footerItem}>
          <TwitterLink />
          <LinkedInLink />
          <GithubLink />
        </div>
        <div className={footerItem}>
          Copyright &copy; {new Date().getFullYear()} Zak Laughton
        </div>
      </div>
    </footer>
  );
};

export default Footer;
